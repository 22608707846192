import React from "react";
import Layout from "../components/Layout";
import PageHeader from "../components/PageHeader";
import TermsAndConditions from "../components/TermsAndConditions";
import Information from "../components/Information";
import Map from "../components/Map";

import "../App.css";

const Terms = () => (
  <Layout>
    <PageHeader text="Terms & Conditions" />
    <TermsAndConditions />
    <Information />
    <Map />
  </Layout>
);

export default Terms;
